.sugc-blue {
  color: #093162 !important;
}

.sugc-gold {
  color: #ffbe4a !important;
}

.bg-sugc-blue {
  background: #093161 !important;
  color: white;
}

.bg-sugc-blue .nav-link {
  color: white !important;
}

.bg-sugc-gold {
  background: #ffbe4a !important;
}

.navbar-toggler {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 1);
  --bs-navbar-color: white;
}

.card-header h2 {
  margin-bottom: 0.5rem;
  text-align: center;
}

.card-header p {
  margin-bottom: 0.1rem;
  text-align: center;
}

.correctly-margined-form > div {
  margin-top: 0.25rem;
  margin-bottom: 0rem;
}

.correctly-margined-form > div label {
  margin-bottom: 0rem;
}

